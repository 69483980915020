.contest-yes {
  padding: 6px;
  display: inline-block;
  background-color: green;
  color: white;
  height: 20px;
  line-height: 8px;
}

.contest-no {
  padding: 6px;
  display: inline-block;
  background-color: gray;
  color: white;
  height: 20px;
  line-height: 8px;
}

/*
 style for table
*/

.MuiDataGrid-row {
  max-height: 30px !important;
  min-height: 30px !important;
}

.MuiDataGrid-cell {
  max-height: 30px !important;
  min-height: 30px !important;
  line-height: 30px !important;
  color: gray !important;
  font-size: 0.758rem;
}

.MuiDataGrid-columnsContainer {
  max-height: 30px !important;
  min-height: 30px !important;
  line-height: 30px !important;
  font-size: 0.758rem;
}

.MuiDataGrid-window {
  top: 33px !important;
}

/* styling genre*/
.genre {
  display: flex;
  margin-bottom: 10px;
}

.genre div {
  border: 1px solid #FF8E53;
  border-radius: 5px;
  padding: 5px 20px;
  margin-left: 5px;
  color: #FF8E53;
}
.genre div:hover {
  cursor: pointer;
}

.genre-active {
  border: 1px solid #FF8E53;
  border-radius: 5px;
  padding: 5px 20px;
  margin-left: 5px;
  color: #fff !important;
  background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) !important;
  cursor: pointer;
}

.song-drawer .MuiPaper-root {
  background-color: white !important;
}
.song-drawer .MuiSvgIcon-root {
  width: 0.7em;
  height: 0.7em;
}
.song-info-container {
  padding: 0px 40px;
  /* display: flex; */
}
.song-cover-photo {
  border: 1px solid gray;
  border-radius: 5px;
  box-shadow: 5px 5px lightgray;
  margin-top: 10px;
}
.song-title{ 
  color: #000;
  text-transform: capitalize;
  padding: 10px 10px 5px 0px;
  font-size: 14px !important;
  max-width: 340px !important;
}
.song-about {
  color: #000;
  text-transform: capitalize;
  margin: 0px;
  font-size: 12px !important;
  max-width: 340px !important;
}
.song-genre {
  padding: 5px 5px;
  color: #000;
  display: inline-block;
}
.song-date {
  padding: 5px 5px;
  margin-left: 5px;
  color: #000;
  display: inline-block;
}
.info-table {
  margin-top: 20px;
}
.info-row {
  display: flex;
  align-content: stretch;
  width: 100%;
}
.info-cell {
  font-size: 11px !important;
  width: 50% !important;
  border: 1px solid gray;
}

.info-cell p {
  margin: 0px;
}

.search-by-date {
  display: inline-block;
  margin-bottom: 15px;
  margin-top: 5px;
  padding: 1px 5px;
  width: 200px !important;
}
.search-by-date-btn {
  display: inline-block;
  border: 1px solid #FF8E53;
  border-radius: 5px;
  padding: 3px 20px;
  margin-left: 5px;
  color: #FF8E53;
}

.search-by-date-btn:hover {
  cursor: pointer;
}
.search-by-date label {
  margin-bottom: 5px !important;
  font-size: 12px;
}

.search-by-date input {
  padding: 5px 5px !important;
}