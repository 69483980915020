/*
 style for table
*/

.MuiDataGrid-row {
    max-height: 30px !important;
    min-height: 30px !important;
}

.MuiDataGrid-cell {
    max-height: 30px !important;
    min-height: 30px !important;
    line-height: 30px !important;
    color: gray !important;
    font-size: .758rem;
}

.MuiDataGrid-columnsContainer {
    max-height: 30px !important;
    min-height: 30px !important;
    line-height: 30px !important;
    font-size: .758rem;
}

.MuiDataGrid-window {
    top: 33px !important;
}
.user-drawer .MuiPaper-root {
    background-color: white !important;
}
