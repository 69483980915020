.transaction-success {
    background-color: #28B463;
    color: white;
    height: 28px;
    font-size: 10px;
    padding: 1px 7px;
}
.transaction-failed {
    background-color: #273746;
    color: white;
    height: 28px;
    font-size: 10px;
    padding: 1px 7px;
}