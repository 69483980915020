.music-card-logo-text {
    font-family: 'Amaranth', sans-serif !important;
    line-height: 30px;
    font-size: 2rem !important;
    line-height: 2.5 !important;
    flex-grow: 1;
}

.left-drawer {
    background-color: #252848;
}

.MuiPaper-root {
    background-color: #252848 !important;
    color: #fff !important;
}

.component-container {
    height: 529px;
    width: 100%;
    display: 'inline-block';
    background: lightgray;
}
.MuiListItemText-root {
	margin-top:0px !important;
}
	
.MuiListItem-root {
    padding-top: 4px !important;
	padding-bottom: 4px !important;
}
/* remove vertical and horizontal scrollbar from the table of dashboard*/
.dashboard-song-container .MuiDataGrid-window{
    overflow: hidden !important;
}

.section-header {
    margin-bottom: 0px !important;
}