.link {
    text-decoration: none;
    color: #fff;
}

.link-selected {
    text-decoration: none;
}

.link-selected>.MuiListItem-root {
    color: #fff;
    background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) !important;
}

.MuiToolbar-regular {
    padding-left: 256px !important;
}

.MuiAppBar-colorPrimary {
    background-color: #fff !important;
    color: #000 !important;
}

.user-profile {
    display: flex !important;
}

.user-profile-welcome {
    margin-top: 12px !important;
}