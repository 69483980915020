.accepted {
    background-color: #28B463;
    color: white;
    height: 28px;
    font-size: 10px;
    padding: 1px 7px;
    margin-left: 2px;
}
.declined {
    background-color: #273746;
    color: white;
    height: 28px;
    font-size: 10px;
    padding: 1px 7px;
    margin-left: 2px;
}
.accept {
    background-color: #28B463;
    color: white;
    height: 28px;
    font-size: 10px;
    padding: 1px 7px;
    margin-left: 2px;
}
.decline {
    background-color: #707B7C;
    color: white;
    height: 28px;
    font-size: 10px;
    padding: 1px 7px;
    margin-left: 2px;
}