.dashboard-info {
    display: inline-block;
    width: 230px;
    padding: 10px;
    border-radius: 7px;
    color: #fff;
    height: 100px;
}

.dashboard-info-count {
    font-size: 30px;
    font-weight: 500;
    margin: 0px;
}

.dashboard-info-text {
    margin: 0px;
}

.dashboard-info-date {
    margin: 0px;
}

.dashboard-primary {
    background: linear-gradient(45deg, #EC4887 30%, #BF53A1 90%) !important;
}

.dashboard-secondary {
    background: linear-gradient(45deg, #875FC0 30%, #5848A2 90%) !important;
    margin-left: 10px;
}

.dashboard-default {
    background: linear-gradient(45deg, #46C5F2 30%, #6495DA 90%) !important;
    margin-left: 10px;
}

.dashboard-users {
    background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) !important;
    margin-left: 10px;
}