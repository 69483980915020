.manage-genre-container {
    border: 1px solid gray;
    margin-top: 5px;
    padding: 20px;
}
.daily-contest-container {
    border: 1px solid gray;
    margin-top: 10px;
    padding: 20px;
}
.daily-contest-timing-container {
    border: 1px solid gray;
    border-top: none;
    padding: 20px;
}
.daily-contest-vote-container {
    border: 1px solid gray;
    border-top: none;
    padding: 20px;
}
.genre-checkbox {
    display: inline-block;
    width: 150px !important;
}

.genre-checkbox input:hover{
    cursor: pointer;
}

.section-header-genre {
    margin-top: 5px;
    margin-bottom: 10px;
}
/** time component related css goes here **/
.contest-start-time {
    display: inline-block;
    margin-left: 10px;
}
.contest-start-time-item {
    display: inline-block;
    margin-left: 8px;
}
.time-select {
    display: block;
    padding: 3px;
}
.to-label {
    margin-left: 7px;
    margin-right: 7px;
    font-weight: 600;
}
.section {
    margin-top: 20px;
}
.mc-label {
    font-weight: 500 !important;
}
.save-setting-container {
    margin-top: 20px;
}
.add-new-genre-input {
    padding: 5px;
    margin-bottom: 5px;
}
.btn-save {
    display: inline-block;
    border: 1px solid #FF8E53;
    border-radius: 5px;
    padding: 6px 20px;
    margin-left: 5px;
    color: #FF8E53;
  }