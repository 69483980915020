html,
body {
    padding: 0;
    margin: 0;
    background-color: #252848;
}

.header {
    margin: 0;
}

.login {
    background-color: #252848;
}

.login-container {
    width: 500px;
    height: 350px;
    border: 1px solid gray;
    border-radius: 10px;
    margin: auto;
    margin-top: 100px;
    text-align: center;
    background-color: #ffffff;
}

.email-field {
    margin-top: 10px
}

.password-field {
    margin-top: 10px;
}

.alert-field {
    margin-top: 10px;
}

.MuiAlert-root {
    width: 356px !important;
    margin: auto;
}

.login-btn {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
}

.btn-primary {
    background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
    border-radius: 3;
    border: 0;
    color: 'white';
    height: 48;
    padding: 0 30px;
    box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
    float: right;
    margin-right: 71px !important;
}

.MuiButton-root {
    max-width: 130px !important;
    min-width: 130px !important;
    text-transform: none !important;
}

.MuiInputBase-root {
    width: 356px !important;
}