.mc-notification {
    border: 1px solid gray;
    margin-top: 10px;
    border-radius: 5px;
    width: 98%;
    padding: 10px;
}
.add-notification-input {
    padding: 5px;
    margin-bottom: 5px;
}
.btn-save {
    display: inline-block;
    border: 1px solid #FF8E53;
    border-radius: 5px;
    padding: 6px 20px;
    margin-left: 5px;
    color: #FF8E53;
  }